const articles = {
    "alkoholizm": {
        "title": "Alkoholizm",
        "subTitle": "Alkoholizm to nie tylko problem z piciem, to choroba, która dotyka całe życie. Razem możemy z nią walczyć i przywrócić równowagę oraz zdrowie.",
        "contentImage": "https://images.unsplash.com/photo-1495399396117-a3763646f854?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "content": `
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Leczenie alkoholizmu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Alkoholizm to poważne i często skomplikowane uzależnienie, które wymaga profesjonalnej i wieloaspektowej terapii. Nasz ośrodek specjalizuje się w leczeniu alkoholizmu, oferując kompleksowe podejście dostosowane do indywidualnych potrzeb każdego pacjenta. Dzięki naszemu doświadczeniu i zaangażowaniu, pomagamy osobom zmagającym się z nałogiem odzyskać kontrolę nad swoim życiem i osiągnąć trwałą trzeźwość. Nasz program terapeutyczny obejmuje:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Detoksykację medyczną pod nadzorem specjalistów.</li>
            <li class="font-light text-gray-500 sm:text-lg">Indywidualne sesje terapeutyczne.</li>
            <li class="font-light text-gray-500 sm:text-lg">Grupy wsparcia i terapie grupowe.</li>
            <li class="font-light text-gray-500 sm:text-lg">Programy edukacyjne dotyczące zdrowia i uzależnień.</li>
            <li class="font-light text-gray-500 sm:text-lg">Warsztaty rozwijające umiejętności radzenia sobie z trudnymi emocjami.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Informacje o alkoholizmie</h1>
        <p class="font-light text-gray-500 sm:text-lg">Alkoholizm, znany również jako choroba alkoholowa, to przewlekłe schorzenie charakteryzujące się niekontrolowanym spożywaniem alkoholu pomimo negatywnych konsekwencji zdrowotnych, społecznych i zawodowych. Kluczowe aspekty alkoholizmu obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Silne pragnienie picia alkoholu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Utrata kontroli nad ilością spożywanego alkoholu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Objawy odstawienne po zaprzestaniu picia.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaniedbywanie obowiązków i relacji z powodu picia.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Kiedy udać się na terapię? – Objawy</h1>
        <p class="font-light text-gray-500 sm:text-lg">Rozpoznanie potrzeby terapii jest kluczowe dla rozpoczęcia procesu zdrowienia. Warto zwrócić uwagę na następujące objawy, które mogą wskazywać na konieczność rozpoczęcia leczenia:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Częste sięganie po alkohol, mimo postanowień o ograniczeniu lub zaprzestaniu picia.</li>
            <li class="font-light text-gray-500 sm:text-lg">Wzrost tolerancji na alkohol, wymagający spożycia większych ilości dla osiągnięcia tego samego efektu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Objawy odstawienia, takie jak drżenie, pocenie się, lęk czy nudności po zaprzestaniu picia.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zmniejszenie zaangażowania w życie rodzinne, zawodowe i społeczne z powodu alkoholu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Picie w sytuacjach, gdzie jest to niebezpieczne, np. prowadzenie pojazdów pod wpływem alkoholu.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Jak leczyć alkoholizm</h1>
        <p class="font-light text-gray-500 sm:text-lg">Leczenie alkoholizmu wymaga zintegrowanego podejścia, które obejmuje zarówno fizyczne, jak i psychiczne aspekty uzależnienia. Proces leczenia zazwyczaj składa się z następujących kroków:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Pierwszy etap leczenia, mający na celu bezpieczne usunięcie alkoholu z organizmu pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia indywidualna:</strong> Praca z terapeutą nad zrozumieniem przyczyn uzależnienia i wypracowaniem strategii radzenia sobie z trudnościami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia grupowa:</strong> Spotkania z innymi osobami zmagającymi się z alkoholizmem, które umożliwiają wymianę doświadczeń i wzajemne wsparcie.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Edukacja:</strong> Zrozumienie mechanizmów uzależnienia i wpływu alkoholu na zdrowie.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Programy wsparcia po zakończeniu terapii, które pomagają utrzymać trzeźwość.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Skuteczne leczenie alkoholizmu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Skuteczność leczenia alkoholizmu zależy od wielu czynników, w tym:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Indywidualnego podejścia dostosowanego do potrzeb pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg">Stałego wsparcia ze strony specjalistów i bliskich.</li>
            <li class="font-light text-gray-500 sm:text-lg">Regularnych sesji terapeutycznych i udziału w grupach wsparcia.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zmiany stylu życia i unikania sytuacji sprzyjających nawrotom.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaangażowania pacjenta w proces leczenia i jego motywacji do trwałej zmiany.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Etapy leczenia alkoholizmu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Proces leczenia alkoholizmu można podzielić na kilka etapów:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rozpoznanie i diagnoza:</strong> Ocena stanu zdrowia pacjenta i poziomu uzależnienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Medyczne usunięcie alkoholu z organizmu i łagodzenie objawów odstawienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rehabilitacja:</strong> Intensywna terapia indywidualna i grupowa, mająca na celu zrozumienie uzależnienia i naukę radzenia sobie z pokusami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Resocjalizacja:</strong> Powrót do normalnego życia, z jednoczesnym wsparciem terapeutycznym.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Długoterminowa pomoc w utrzymaniu trzeźwości i zapobieganiu nawrotom.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Ile trwa leczenie alkoholizmu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Czas trwania leczenia alkoholizmu może się znacznie różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. Przykładowo:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Zazwyczaj trwa od kilku dni do dwóch tygodni.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Intensywna terapia:</strong> Może trwać od kilku tygodni do kilku miesięcy.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Może być konieczne przez wiele lat, a nawet przez całe życie, w formie regularnych spotkań grup wsparcia i sesji terapeutycznych.</li>
        </ul>

        <p class="font-light text-gray-500 sm:text-lg">Skuteczne leczenie alkoholizmu to proces długotrwały i wymagający zaangażowania zarówno ze strony pacjenta, jak i zespołu terapeutycznego. Dzięki naszemu ośrodkowi, każdy pacjent ma szansę na powrót do zdrowego i trzeźwego życia.</p>
        `
    },
    "narkomania": {
        "title": "Narkomania",
        "subTitle": "Narkomania to niebezpieczna pułapka, która niszczy życie. Nasza misja to wspólna walka i pomoc w odzyskaniu kontroli oraz zdrowia.",
        "contentImage": "https://images.unsplash.com/photo-1611690827904-0ebe4fe34bd8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "content": `
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Leczenie narkomanii</h1>
        <p class="font-light text-gray-500 sm:text-lg">Narkomania to poważne uzależnienie od substancji psychoaktywnych, które wymaga specjalistycznej i zindywidualizowanej terapii. Nasz ośrodek oferuje kompleksowe programy terapeutyczne dostosowane do indywidualnych potrzeb każdego pacjenta. Nasze podejście obejmuje zarówno aspekty medyczne, jak i psychologiczne, zapewniając wsparcie na każdym etapie leczenia. Nasze programy terapeutyczne obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Detoksykację pod opieką medyczną.</li>
            <li class="font-light text-gray-500 sm:text-lg">Indywidualne i grupowe sesje terapeutyczne.</li>
            <li class="font-light text-gray-500 sm:text-lg">Programy edukacyjne dotyczące zdrowia i uzależnień.</li>
            <li class="font-light text-gray-500 sm:text-lg">Warsztaty rozwijające umiejętności radzenia sobie z emocjami.</li>
            <li class="font-light text-gray-500 sm:text-lg">Wsparcie po zakończeniu terapii.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Informacje o narkomanii</h1>
        <p class="font-light text-gray-500 sm:text-lg">Narkomania to chroniczne uzależnienie od narkotyków, które wpływa na funkcjonowanie mózgu i zachowanie osoby uzależnionej. Charakteryzuje się niekontrolowanym pragnieniem zażywania narkotyków, pomimo negatywnych konsekwencji zdrowotnych, społecznych i zawodowych. Kluczowe aspekty narkomanii obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Silne pragnienie zażywania narkotyków.</li>
            <li class="font-light text-gray-500 sm:text-lg">Utrata kontroli nad ilością zażywanych substancji.</li>
            <li class="font-light text-gray-500 sm:text-lg">Objawy odstawienia po zaprzestaniu zażywania.</li>
            <li class="font-light text-gray-500 sm:text-lg">Rezygnacja z obowiązków i relacji na rzecz zażywania narkotyków.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Kiedy udać się na terapię? – Objawy</h1>
        <p class="font-light text-gray-500 sm:text-lg">Rozpoznanie potrzeby terapii jest kluczowe dla rozpoczęcia procesu zdrowienia. Warto zwrócić uwagę na następujące objawy, które mogą wskazywać na konieczność rozpoczęcia leczenia:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Częste zażywanie narkotyków, mimo postanowień o ograniczeniu lub zaprzestaniu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Wzrost tolerancji na narkotyki, wymagający większych dawek dla osiągnięcia tego samego efektu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Objawy odstawienia, takie jak drżenie, pocenie się, lęk czy nudności po zaprzestaniu zażywania.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaniedbywanie obowiązków i relacji z powodu zażywania narkotyków.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zażywanie narkotyków w niebezpiecznych sytuacjach, np. prowadzenie pojazdów pod wpływem substancji.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Jak leczyć narkomanię</h1>
        <p class="font-light text-gray-500 sm:text-lg">Leczenie narkomanii wymaga zintegrowanego podejścia, które obejmuje zarówno fizyczne, jak i psychiczne aspekty uzależnienia. Proces leczenia zazwyczaj składa się z następujących kroków:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Pierwszy etap leczenia, mający na celu bezpieczne usunięcie substancji z organizmu pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia indywidualna:</strong> Praca z terapeutą nad zrozumieniem przyczyn uzależnienia i wypracowaniem strategii radzenia sobie z trudnościami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia grupowa:</strong> Spotkania z innymi osobami zmagającymi się z narkomanią, które umożliwiają wymianę doświadczeń i wzajemne wsparcie.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Edukacja:</strong> Zrozumienie mechanizmów uzależnienia i wpływu narkotyków na zdrowie.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Programy wsparcia po zakończeniu terapii, które pomagają utrzymać abstynencję.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Skuteczne leczenie narkomanii</h1>
        <p class="font-light text-gray-500 sm:text-lg">Skuteczność leczenia narkomanii zależy od wielu czynników, w tym:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Indywidualnego podejścia dostosowanego do potrzeb pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg">Stałego wsparcia ze strony specjalistów i bliskich.</li>
            <li class="font-light text-gray-500 sm:text-lg">Regularnych sesji terapeutycznych i udziału w grupach wsparcia.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zmiany stylu życia i unikania sytuacji sprzyjających nawrotom.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaangażowania pacjenta w proces leczenia i jego motywacji do trwałej zmiany.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Etapy leczenia narkomanii</h1>
        <p class="font-light text-gray-500 sm:text-lg">Proces leczenia narkomanii można podzielić na kilka etapów:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rozpoznanie i diagnoza:</strong> Ocena stanu zdrowia pacjenta i poziomu uzależnienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Medyczne usunięcie substancji z organizmu i łagodzenie objawów odstawienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rehabilitacja:</strong> Intensywna terapia indywidualna i grupowa, mająca na celu zrozumienie uzależnienia i naukę radzenia sobie z pokusami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Resocjalizacja:</strong> Powrót do normalnego życia, z jednoczesnym wsparciem terapeutycznym.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Długoterminowa pomoc w utrzymaniu abstynencji i zapobieganiu nawrotom.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Ile trwa leczenie narkomanii</h1>
        <p class="font-light text-gray-500 sm:text-lg">Czas trwania leczenia narkomanii może się znacznie różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. Przykładowo:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Zazwyczaj trwa od kilku dni do dwóch tygodni.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Intensywna terapia:</strong> Może trwać od kilku tygodni do kilku miesięcy.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Może być konieczne przez wiele lat, a nawet przez całe życie, w formie regularnych spotkań grup wsparcia i sesji terapeutycznych.</li>
        </ul>

        <p class="font-light text-gray-500 sm:text-lg">Skuteczne leczenie narkomanii to proces długotrwały i wymagający zaangażowania zarówno ze strony pacjenta, jak i zespołu terapeutycznego. Dzięki naszemu ośrodkowi, każdy pacjent ma szansę na powrót do zdrowego i trzeźwego życia.</p>
        `
    },
    "lekomania": {
        "title": "Lekomania",
        "subTitle": "Lekomania to uzależnienie, które może zniszczyć zdrowie i życie. Wspólnie możemy przezwyciężyć ten problem i wrócić do pełni sił.",
        "contentImage": "https://images.unsplash.com/photo-1675524375058-0b0f72f5d3b8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Leczenie lekomanii</h1>
        <p class="font-light text-gray-500 sm:text-lg">Lekomania, czyli uzależnienie od leków, to poważne zaburzenie, które wymaga specjalistycznej i indywidualnie dostosowanej terapii. Nasz ośrodek oferuje kompleksowe programy terapeutyczne, które pomagają pacjentom odzyskać kontrolę nad swoim życiem i uwolnić się od nałogu. Nasze podejście obejmuje zarówno aspekty medyczne, jak i psychologiczne, zapewniając wsparcie na każdym etapie leczenia. Nasze programy terapeutyczne obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Detoksykację pod opieką medyczną.</li>
            <li class="font-light text-gray-500 sm:text-lg">Indywidualne i grupowe sesje terapeutyczne.</li>
            <li class="font-light text-gray-500 sm:text-lg">Programy edukacyjne dotyczące zdrowia i uzależnień.</li>
            <li class="font-light text-gray-500 sm:text-lg">Warsztaty rozwijające umiejętności radzenia sobie z emocjami.</li>
            <li class="font-light text-gray-500 sm:text-lg">Wsparcie po zakończeniu terapii.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Informacje o lekomanii</h1>
        <p class="font-light text-gray-500 sm:text-lg">Lekomania to uzależnienie od leków, które może prowadzić do poważnych konsekwencji zdrowotnych, społecznych i zawodowych. Charakteryzuje się niekontrolowanym spożywaniem leków, często w ilościach przekraczających zalecane dawki, oraz trudnościami w zaprzestaniu ich przyjmowania. Kluczowe aspekty lekomanii obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Silne pragnienie zażywania leków.</li>
            <li class="font-light text-gray-500 sm:text-lg">Utrata kontroli nad ilością spożywanych leków.</li>
            <li class="font-light text-gray-500 sm:text-lg">Objawy odstawienne po zaprzestaniu zażywania.</li>
            <li class="font-light text-gray-500 sm:text-lg">Rezygnacja z obowiązków i relacji na rzecz zażywania leków.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Kiedy udać się na terapię? – Objawy</h1>
        <p class="font-light text-gray-500 sm:text-lg">Rozpoznanie potrzeby terapii jest kluczowe dla rozpoczęcia procesu zdrowienia. Warto zwrócić uwagę na następujące objawy, które mogą wskazywać na konieczność rozpoczęcia leczenia:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Częste zażywanie leków, mimo postanowień o ograniczeniu lub zaprzestaniu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Wzrost tolerancji na leki, wymagający większych dawek dla osiągnięcia tego samego efektu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Objawy odstawienia, takie jak drżenie, pocenie się, lęk czy nudności po zaprzestaniu zażywania.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaniedbywanie obowiązków i relacji z powodu zażywania leków.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zażywanie leków w niebezpiecznych sytuacjach, np. prowadzenie pojazdów pod wpływem substancji.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Jak leczyć lekomanię</h1>
        <p class="font-light text-gray-500 sm:text-lg">Leczenie lekomanii wymaga zintegrowanego podejścia, które obejmuje zarówno fizyczne, jak i psychiczne aspekty uzależnienia. Proces leczenia zazwyczaj składa się z następujących kroków:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Pierwszy etap leczenia, mający na celu bezpieczne usunięcie substancji z organizmu pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia indywidualna:</strong> Praca z terapeutą nad zrozumieniem przyczyn uzależnienia i wypracowaniem strategii radzenia sobie z trudnościami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia grupowa:</strong> Spotkania z innymi osobami zmagającymi się z lekomanią, które umożliwiają wymianę doświadczeń i wzajemne wsparcie.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Edukacja:</strong> Zrozumienie mechanizmów uzależnienia i wpływu leków na zdrowie.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Programy wsparcia po zakończeniu terapii, które pomagają utrzymać abstynencję.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Skuteczne leczenie lekomanii</h1>
        <p class="font-light text-gray-500 sm:text-lg">Skuteczność leczenia lekomanii zależy od wielu czynników, w tym:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Indywidualnego podejścia dostosowanego do potrzeb pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg">Stałego wsparcia ze strony specjalistów i bliskich.</li>
            <li class="font-light text-gray-500 sm:text-lg">Regularnych sesji terapeutycznych i udziału w grupach wsparcia.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zmiany stylu życia i unikania sytuacji sprzyjających nawrotom.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaangażowania pacjenta w proces leczenia i jego motywacji do trwałej zmiany.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Etapy leczenia lekomanii</h1>
        <p class="font-light text-gray-500 sm:text-lg">Proces leczenia lekomanii można podzielić na kilka etapów:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rozpoznanie i diagnoza:</strong> Ocena stanu zdrowia pacjenta i poziomu uzależnienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Medyczne usunięcie substancji z organizmu i łagodzenie objawów odstawienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rehabilitacja:</strong> Intensywna terapia indywidualna i grupowa, mająca na celu zrozumienie uzależnienia i naukę radzenia sobie z pokusami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Resocjalizacja:</strong> Powrót do normalnego życia, z jednoczesnym wsparciem terapeutycznym.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Długoterminowa pomoc w utrzymaniu abstynencji i zapobieganiu nawrotom.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Ile trwa leczenie lekomanii</h1>
        <p class="font-light text-gray-500 sm:text-lg">Czas trwania leczenia lekomanii może się znacznie różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. Przykładowo:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Zazwyczaj trwa od kilku dni do dwóch tygodni.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Intensywna terapia:</strong> Może trwać od kilku tygodni do kilku miesięcy.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Może być konieczne przez wiele lat, a nawet przez całe życie, w formie regularnych spotkań grup wsparcia i sesji terapeutycznych.</li>
        </ul>

        <p class="font-light text-gray-500 sm:text-lg">Skuteczne leczenie lekomanii to proces długotrwały i wymagający zaangażowania zarówno ze strony pacjenta, jak i zespołu terapeutycznego. Dzięki naszemu ośrodkowi, każdy pacjent ma szansę na powrót do zdrowego i trzeźwego życia.</p>
        `
    },
    "hazard": {
        "title": "Hazard",
        "subTitle": "Hazard to uzależnienie, które może prowadzić do poważnych problemów finansowych i osobistych. Razem możemy odzyskać kontrolę nad życiem.",
        "contentImage": "https://images.unsplash.com/photo-1608231883522-2efb1897a608?q=80&w=1980&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Leczenie uzależnienia od hazardu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Uzależnienie od hazardu to poważne zaburzenie, które wymaga specjalistycznej i zindywidualizowanej terapii. Nasz ośrodek oferuje kompleksowe programy terapeutyczne dostosowane do indywidualnych potrzeb każdego pacjenta, pomagając im odzyskać kontrolę nad swoim życiem i uwolnić się od nałogu. Nasze podejście obejmuje zarówno aspekty psychologiczne, jak i społecznościowe, zapewniając wsparcie na każdym etapie leczenia. Nasze programy terapeutyczne obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Indywidualne i grupowe sesje terapeutyczne.</li>
            <li class="font-light text-gray-500 sm:text-lg">Programy edukacyjne dotyczące zdrowia psychicznego i uzależnień.</li>
            <li class="font-light text-gray-500 sm:text-lg">Warsztaty rozwijające umiejętności radzenia sobie z emocjami i stresem.</li>
            <li class="font-light text-gray-500 sm:text-lg">Wsparcie po zakończeniu terapii.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Informacje o uzależnieniu od hazardu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Uzależnienie od hazardu to kompulsywne zachowanie polegające na niekontrolowanym uczestnictwie w grach hazardowych, które prowadzi do poważnych konsekwencji finansowych, społecznych i zdrowotnych. Kluczowe aspekty uzależnienia od hazardu obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Silne pragnienie uczestniczenia w grach hazardowych.</li>
            <li class="font-light text-gray-500 sm:text-lg">Utrata kontroli nad częstotliwością i ilością czasu spędzanego na hazardzie.</li>
            <li class="font-light text-gray-500 sm:text-lg">Kłopoty finansowe wynikające z hazardu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaniedbywanie obowiązków rodzinnych, zawodowych i społecznych na rzecz hazardu.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Kiedy udać się na terapię? – Objawy</h1>
        <p class="font-light text-gray-500 sm:text-lg">Rozpoznanie potrzeby terapii jest kluczowe dla rozpoczęcia procesu zdrowienia. Warto zwrócić uwagę na następujące objawy, które mogą wskazywać na konieczność rozpoczęcia leczenia:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Częste uczestniczenie w grach hazardowych mimo postanowień o zaprzestaniu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Podejmowanie ryzyka finansowego, które przekracza możliwości budżetowe.</li>
            <li class="font-light text-gray-500 sm:text-lg">Odczuwanie silnego stresu, lęku lub depresji związanego z hazardem.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaniedbywanie pracy, rodziny i innych obowiązków na rzecz hazardu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Ukrywanie skali problemu przed bliskimi.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Jak leczyć uzależnienie od hazardu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Leczenie uzależnienia od hazardu wymaga zintegrowanego podejścia, które obejmuje zarówno psychologiczne, jak i społecznościowe aspekty uzależnienia. Proces leczenia zazwyczaj składa się z następujących kroków:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia indywidualna:</strong> Praca z terapeutą nad zrozumieniem przyczyn uzależnienia i wypracowaniem strategii radzenia sobie z trudnościami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia grupowa:</strong> Spotkania z innymi osobami zmagającymi się z uzależnieniem od hazardu, które umożliwiają wymianę doświadczeń i wzajemne wsparcie.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Edukacja:</strong> Zrozumienie mechanizmów uzależnienia i wpływu hazardu na zdrowie psychiczne.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Programy wsparcia po zakończeniu terapii, które pomagają utrzymać abstynencję od hazardu.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Skuteczne leczenie uzależnienia od hazardu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Skuteczność leczenia uzależnienia od hazardu zależy od wielu czynników, w tym:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Indywidualnego podejścia dostosowanego do potrzeb pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg">Stałego wsparcia ze strony specjalistów i bliskich.</li>
            <li class="font-light text-gray-500 sm:text-lg">Regularnych sesji terapeutycznych i udziału w grupach wsparcia.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zmiany stylu życia i unikania sytuacji sprzyjających nawrotom.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaangażowania pacjenta w proces leczenia i jego motywacji do trwałej zmiany.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Etapy leczenia uzależnienia od hazardu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Proces leczenia uzależnienia od hazardu można podzielić na kilka etapów:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rozpoznanie i diagnoza:</strong> Ocena stanu psychicznego pacjenta i poziomu uzależnienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia indywidualna:</strong> Praca z terapeutą nad zrozumieniem uzależnienia i wypracowaniem strategii radzenia sobie z pokusami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rehabilitacja:</strong> Intensywna terapia indywidualna i grupowa, mająca na celu zrozumienie uzależnienia i naukę radzenia sobie z trudnymi sytuacjami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Resocjalizacja:</strong> Powrót do normalnego życia, z jednoczesnym wsparciem terapeutycznym.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Długoterminowa pomoc w utrzymaniu abstynencji od hazardu i zapobieganiu nawrotom.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Ile trwa leczenie uzależnienia od hazardu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Czas trwania leczenia uzależnienia od hazardu może się znacznie różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. Przykładowo:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia indywidualna:</strong> Zazwyczaj trwa od kilku tygodni do kilku miesięcy.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Intensywna terapia grupowa:</strong> Może trwać od kilku tygodni do kilku miesięcy.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Może być konieczne przez wiele lat, a nawet przez całe życie, w formie regularnych spotkań grup wsparcia i sesji terapeutycznych.</li>
        </ul>

        <p class="font-light text-gray-500 sm:text-lg">Skuteczne leczenie uzależnienia od hazardu to proces długotrwały i wymagający zaangażowania zarówno ze strony pacjenta, jak i zespołu terapeutycznego. Dzięki naszemu ośrodkowi, każdy pacjent ma szansę na powrót do zdrowego i kontrolowanego życia.</p>
        `
    },
    "seksoholizm": {
        "title": "Seksoholizm",
        "subTitle": "Seksoholizm to uzależnienie, które może zrujnować relacje i życie osobiste. Wspólnie możemy znaleźć drogę do zdrowia i harmonii.",
        "contentImage": "https://images.unsplash.com/photo-1606103836293-0a063ee20566?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Leczenie seksoholizmu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Seksoholizm, czyli uzależnienie od seksu, to poważne zaburzenie, które wymaga specjalistycznej i indywidualnie dostosowanej terapii. Nasz ośrodek oferuje kompleksowe programy terapeutyczne, które pomagają pacjentom odzyskać kontrolę nad swoim życiem i uwolnić się od nałogu. Nasze podejście obejmuje zarówno aspekty psychologiczne, jak i behawioralne, zapewniając wsparcie na każdym etapie leczenia. Nasze programy terapeutyczne obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Indywidualne i grupowe sesje terapeutyczne.</li>
            <li class="font-light text-gray-500 sm:text-lg">Programy edukacyjne dotyczące zdrowia psychicznego i uzależnień.</li>
            <li class="font-light text-gray-500 sm:text-lg">Warsztaty rozwijające umiejętności radzenia sobie z emocjami i stresem.</li>
            <li class="font-light text-gray-500 sm:text-lg">Wsparcie po zakończeniu terapii.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Informacje o seksoholizmie</h1>
        <p class="font-light text-gray-500 sm:text-lg">Seksoholizm to uzależnienie od aktywności seksualnej, które może prowadzić do poważnych konsekwencji zdrowotnych, społecznych i zawodowych. Charakteryzuje się niekontrolowanym pragnieniem angażowania się w aktywności seksualne, często kosztem innych aspektów życia. Kluczowe aspekty seksoholizmu obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Silne pragnienie angażowania się w aktywności seksualne.</li>
            <li class="font-light text-gray-500 sm:text-lg">Utrata kontroli nad częstotliwością i ilością czasu spędzanego na aktywnościach seksualnych.</li>
            <li class="font-light text-gray-500 sm:text-lg">Problemy w relacjach interpersonalnych z powodu nadmiernej aktywności seksualnej.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaniedbywanie obowiązków zawodowych i społecznych na rzecz aktywności seksualnych.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Kiedy udać się na terapię? – Objawy</h1>
        <p class="font-light text-gray-500 sm:text-lg">Rozpoznanie potrzeby terapii jest kluczowe dla rozpoczęcia procesu zdrowienia. Warto zwrócić uwagę na następujące objawy, które mogą wskazywać na konieczność rozpoczęcia leczenia:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Częste angażowanie się w aktywności seksualne mimo postanowień o ograniczeniu lub zaprzestaniu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Podejmowanie ryzykownych zachowań seksualnych.</li>
            <li class="font-light text-gray-500 sm:text-lg">Odczuwanie silnego stresu, lęku lub depresji związanego z aktywnością seksualną.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaniedbywanie pracy, rodziny i innych obowiązków na rzecz aktywności seksualnych.</li>
            <li class="font-light text-gray-500 sm:text-lg">Ukrywanie skali problemu przed bliskimi.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Jak leczyć seksoholizm</h1>
        <p class="font-light text-gray-500 sm:text-lg">Leczenie seksoholizmu wymaga zintegrowanego podejścia, które obejmuje zarówno psychologiczne, jak i behawioralne aspekty uzależnienia. Proces leczenia zazwyczaj składa się z następujących kroków:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia indywidualna:</strong> Praca z terapeutą nad zrozumieniem przyczyn uzależnienia i wypracowaniem strategii radzenia sobie z trudnościami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia grupowa:</strong> Spotkania z innymi osobami zmagającymi się z seksoholizmem, które umożliwiają wymianę doświadczeń i wzajemne wsparcie.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Edukacja:</strong> Zrozumienie mechanizmów uzależnienia i wpływu aktywności seksualnej na zdrowie psychiczne.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Programy wsparcia po zakończeniu terapii, które pomagają utrzymać zdrowe nawyki.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Skuteczne leczenie seksoholizmu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Skuteczność leczenia seksoholizmu zależy od wielu czynników, w tym:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Indywidualnego podejścia dostosowanego do potrzeb pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg">Stałego wsparcia ze strony specjalistów i bliskich.</li>
            <li class="font-light text-gray-500 sm:text-lg">Regularnych sesji terapeutycznych i udziału w grupach wsparcia.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zmiany stylu życia i unikania sytuacji sprzyjających nawrotom.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaangażowania pacjenta w proces leczenia i jego motywacji do trwałej zmiany.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Etapy leczenia seksoholizmu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Proces leczenia seksoholizmu można podzielić na kilka etapów:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rozpoznanie i diagnoza:</strong> Ocena stanu psychicznego pacjenta i poziomu uzależnienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia indywidualna:</strong> Praca z terapeutą nad zrozumieniem uzależnienia i wypracowaniem strategii radzenia sobie z pokusami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rehabilitacja:</strong> Intensywna terapia indywidualna i grupowa, mająca na celu zrozumienie uzależnienia i naukę radzenia sobie z trudnymi sytuacjami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Resocjalizacja:</strong> Powrót do normalnego życia, z jednoczesnym wsparciem terapeutycznym.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Długoterminowa pomoc w utrzymaniu zdrowych nawyków i zapobieganiu nawrotom.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Ile trwa leczenie seksoholizmu</h1>
        <p class="font-light text-gray-500 sm:text-lg">Czas trwania leczenia seksoholizmu może się znacznie różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. Przykładowo:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia indywidualna:</strong> Zazwyczaj trwa od kilku tygodni do kilku miesięcy.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Intensywna terapia grupowa:</strong> Może trwać od kilku tygodni do kilku miesięcy.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Może być konieczne przez wiele lat, a nawet przez całe życie, w formie regularnych spotkań grup wsparcia i sesji terapeutycznych.</li>
        </ul>

        <p class="font-light text-gray-500 sm:text-lg">Skuteczne leczenie seksoholizmu to proces długotrwały i wymagający zaangażowania zarówno ze strony pacjenta, jak i zespołu terapeutycznego. Dzięki naszemu ośrodkowi, każdy pacjent ma szansę na powrót do zdrowego i kontrolowanego życia.</p>
        `
    },
    "mieszane": {
        "title": "Mieszane",
        "subTitle": "Uzależnienia mieszane to złożony problem, który wymaga wszechstronnego podejścia. Razem znajdziemy drogę do zdrowia i równowagi.",
        "contentImage": "https://images.unsplash.com/photo-1472566316349-bce77aa2a778?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Leczenie uzależnień mieszanych</h1>
        <p class="font-light text-gray-500 sm:text-lg">Uzależnienia mieszane, czyli współwystępowanie więcej niż jednego rodzaju uzależnienia, to szczególnie skomplikowane zaburzenie, które wymaga zintegrowanego i indywidualnie dostosowanego podejścia terapeutycznego. Nasz ośrodek oferuje kompleksowe programy terapeutyczne, które pomagają pacjentom poradzić sobie z różnorodnymi uzależnieniami jednocześnie. Nasze podejście obejmuje zarówno aspekty medyczne, jak i psychologiczne, zapewniając wsparcie na każdym etapie leczenia. Nasze programy terapeutyczne obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Detoksykację pod opieką medyczną.</li>
            <li class="font-light text-gray-500 sm:text-lg">Indywidualne i grupowe sesje terapeutyczne.</li>
            <li class="font-light text-gray-500 sm:text-lg">Programy edukacyjne dotyczące zdrowia psychicznego i uzależnień.</li>
            <li class="font-light text-gray-500 sm:text-lg">Warsztaty rozwijające umiejętności radzenia sobie z emocjami i stresem.</li>
            <li class="font-light text-gray-500 sm:text-lg">Wsparcie po zakończeniu terapii.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Informacje o uzależnieniach mieszanych</h1>
        <p class="font-light text-gray-500 sm:text-lg">Uzależnienia mieszane polegają na jednoczesnym występowaniu więcej niż jednego rodzaju uzależnienia, co może prowadzić do poważnych konsekwencji zdrowotnych, społecznych i zawodowych. Współwystępowanie uzależnień często komplikuje proces leczenia, ponieważ każde uzależnienie wpływa na inne. Kluczowe aspekty uzależnień mieszanych obejmują:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Silne pragnienie angażowania się w różne nałogowe zachowania.</li>
            <li class="font-light text-gray-500 sm:text-lg">Utrata kontroli nad częstotliwością i ilością czasu spędzanego na tych zachowaniach.</li>
            <li class="font-light text-gray-500 sm:text-lg">Problemy w relacjach interpersonalnych z powodu różnych uzależnień.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaniedbywanie obowiązków zawodowych i społecznych na rzecz uzależnień.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Kiedy udać się na terapię? – Objawy</h1>
        <p class="font-light text-gray-500 sm:text-lg">Rozpoznanie potrzeby terapii jest kluczowe dla rozpoczęcia procesu zdrowienia. Warto zwrócić uwagę na następujące objawy, które mogą wskazywać na konieczność rozpoczęcia leczenia uzależnień mieszanych:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Częste angażowanie się w różne nałogowe zachowania mimo postanowień o ograniczeniu lub zaprzestaniu.</li>
            <li class="font-light text-gray-500 sm:text-lg">Podejmowanie ryzykownych zachowań związanych z różnymi uzależnieniami.</li>
            <li class="font-light text-gray-500 sm:text-lg">Odczuwanie silnego stresu, lęku lub depresji związanego z uzależnieniami.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaniedbywanie pracy, rodziny i innych obowiązków na rzecz uzależnień.</li>
            <li class="font-light text-gray-500 sm:text-lg">Ukrywanie skali problemu przed bliskimi.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Jak leczyć uzależnienia mieszane</h1>
        <p class="font-light text-gray-500 sm:text-lg">Leczenie uzależnień mieszanych wymaga zintegrowanego podejścia, które obejmuje zarówno fizyczne, jak i psychiczne aspekty uzależnień. Proces leczenia zazwyczaj składa się z następujących kroków:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Pierwszy etap leczenia, mający na celu bezpieczne usunięcie substancji uzależniających z organizmu pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia indywidualna:</strong> Praca z terapeutą nad zrozumieniem przyczyn uzależnień i wypracowaniem strategii radzenia sobie z trudnościami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Terapia grupowa:</strong> Spotkania z innymi osobami zmagającymi się z uzależnieniami, które umożliwiają wymianę doświadczeń i wzajemne wsparcie.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Edukacja:</strong> Zrozumienie mechanizmów uzależnień i wpływu na zdrowie psychiczne.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Programy wsparcia po zakończeniu terapii, które pomagają utrzymać zdrowe nawyki.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Skuteczne leczenie uzależnień mieszanych</h1>
        <p class="font-light text-gray-500 sm:text-lg">Skuteczność leczenia uzależnień mieszanych zależy od wielu czynników, w tym:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg">Indywidualnego podejścia dostosowanego do potrzeb pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg">Stałego wsparcia ze strony specjalistów i bliskich.</li>
            <li class="font-light text-gray-500 sm:text-lg">Regularnych sesji terapeutycznych i udziału w grupach wsparcia.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zmiany stylu życia i unikania sytuacji sprzyjających nawrotom.</li>
            <li class="font-light text-gray-500 sm:text-lg">Zaangażowania pacjenta w proces leczenia i jego motywacji do trwałej zmiany.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Etapy leczenia uzależnień mieszanych</h1>
        <p class="font-light text-gray-500 sm:text-lg">Proces leczenia uzależnień mieszanych można podzielić na kilka etapów:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rozpoznanie i diagnoza:</strong> Ocena stanu zdrowia pacjenta i poziomu uzależnień.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Medyczne usunięcie substancji uzależniających z organizmu i łagodzenie objawów odstawienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Rehabilitacja:</strong> Intensywna terapia indywidualna i grupowa, mająca na celu zrozumienie uzależnień i naukę radzenia sobie z trudnymi sytuacjami.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Resocjalizacja:</strong> Powrót do normalnego życia, z jednoczesnym wsparciem terapeutycznym.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Długoterminowa pomoc w utrzymaniu zdrowych nawyków i zapobieganiu nawrotom.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Ile trwa leczenie uzależnień mieszanych</h1>
        <p class="font-light text-gray-500 sm:text-lg">Czas trwania leczenia uzależnień mieszanych może się znacznie różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnień. Przykładowo:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Detoksykacja:</strong> Zazwyczaj trwa od kilku dni do dwóch tygodni.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Intensywna terapia:</strong> Może trwać od kilku tygodni do kilku miesięcy.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie po leczeniu:</strong> Może być konieczne przez wiele lat, a nawet przez całe życie, w formie regularnych spotkań grup wsparcia i sesji terapeutycznych.</li>
        </ul>

        <p class="font-light text-gray-500 sm:text-lg">Skuteczne leczenie uzależnień mieszanych to proces długotrwały i wymagający zaangażowania zarówno ze strony pacjenta, jak i zespołu terapeutycznego. Dzięki naszemu ośrodkowi, każdy pacjent ma szansę na powrót do zdrowego i kontrolowanego życia.</p>
        `
    },
    "odtrucie_alkoholowe": {
        "title": "Odtrucie alkoholowe",
        "subTitle": "Odtrucie alkoholowe to pierwszy krok do zdrowia. Oferujemy profesjonalną pomoc w bezpiecznym i skutecznym oczyszczeniu organizmu z alkoholu.",
        "contentImage": "https://images.unsplash.com/photo-1608272667943-cbf5ee73c0fa?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Odtrucie alkoholowe</h1>
        <p class="font-light text-gray-500 sm:text-lg">Odtrucie alkoholowe, znane również jako detoksykacja alkoholowa, to proces medyczny mający na celu usunięcie alkoholu z organizmu pacjenta oraz łagodzenie objawów odstawienia. Jest to pierwszy i kluczowy etap leczenia uzależnienia od alkoholu, który przygotowuje pacjenta do dalszej terapii. Nasz ośrodek oferuje profesjonalne i bezpieczne odtrucie alkoholowe pod opieką doświadczonego zespołu medycznego.</p>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Na czym polega detoks</h1>
        <p class="font-light text-gray-500 sm:text-lg">Detoksykacja alkoholowa polega na monitorowanym procesie eliminacji alkoholu z organizmu, który odbywa się pod nadzorem specjalistów. Proces ten obejmuje:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Ocena stanu zdrowia:</strong> Przeprowadzenie wstępnych badań i ocena stanu zdrowia pacjenta, aby dostosować plan detoksykacji.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Monitorowanie medyczne:</strong> Stałe monitorowanie stanu zdrowia pacjenta przez zespół medyczny, w tym kontrola ciśnienia krwi, tętna i poziomu nawodnienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Łagodzenie objawów odstawienia:</strong> Podawanie odpowiednich leków i płynów w celu złagodzenia objawów odstawienia, takich jak drżenie, nudności, pocenie się i niepokój.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie psychologiczne:</strong> Zapewnienie wsparcia psychologicznego przez terapeutów i specjalistów ds. uzależnień.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Dlaczego detoks jest konieczny?</h1>
        <p class="font-light text-gray-500 sm:text-lg">Detoksykacja alkoholowa jest konieczna z kilku kluczowych powodów:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Bezpieczeństwo:</strong> Nagłe odstawienie alkoholu może być niebezpieczne i prowadzić do poważnych komplikacji zdrowotnych, takich jak napady drgawkowe, halucynacje czy delirium tremens. Detoks pod nadzorem medycznym zapewnia bezpieczeństwo pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Łagodzenie objawów odstawienia:</strong> Profesjonalna detoksykacja pomaga złagodzić objawy odstawienia, co zmniejsza dyskomfort i ryzyko powikłań.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Przygotowanie do dalszej terapii:</strong> Detoksykacja jest pierwszym krokiem w leczeniu uzależnienia od alkoholu, przygotowując organizm pacjenta do dalszych etapów terapii, takich jak terapia indywidualna, grupowa i resocjalizacja.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Poprawa zdrowia fizycznego i psychicznego:</strong> Usunięcie alkoholu z organizmu poprawia ogólny stan zdrowia pacjenta, zarówno fizyczny, jak i psychiczny, co jest kluczowe dla sukcesu dalszego leczenia.</li>
        </ul>

        <p class="font-light text-gray-500 sm:text-lg">Detoksykacja alkoholowa to kluczowy krok w procesie zdrowienia z uzależnienia. Dzięki naszemu ośrodkowi, pacjenci mogą liczyć na bezpieczne i skuteczne odtrucie pod opieką doświadczonych specjalistów, co stanowi solidną podstawę do dalszej terapii i powrotu do zdrowego, trzeźwego życia.</p>
        `
    },
    "odtrucie_narkotykowe": {
        "title": "Odtrucie narkotykowe",
        "subTitle": "Odtrucie narkotykowe to kluczowy etap w walce z uzależnieniem. Zapewniamy kompleksową opiekę i wsparcie, aby bezpiecznie oczyścić organizm z narkotyków.",
        "contentImage": "https://images.unsplash.com/photo-1620065692553-8d79bd124f0d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Odtrucie narkotykowe</h1>
        <p class="font-light text-gray-500 sm:text-lg">Odtrucie narkotykowe, znane również jako detoksykacja narkotykowa, to proces medyczny mający na celu usunięcie substancji psychoaktywnych z organizmu pacjenta oraz złagodzenie objawów odstawienia. Jest to kluczowy pierwszy krok w leczeniu uzależnienia od narkotyków, który przygotowuje pacjenta do dalszej terapii. Nasz ośrodek oferuje profesjonalne i bezpieczne odtrucie narkotykowe pod opieką doświadczonego zespołu medycznego.</p>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Na czym polega detoks</h1>
        <p class="font-light text-gray-500 sm:text-lg">Detoksykacja narkotykowa polega na monitorowanym procesie eliminacji substancji psychoaktywnych z organizmu, który odbywa się pod nadzorem specjalistów. Proces ten obejmuje:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Ocena stanu zdrowia:</strong> Przeprowadzenie wstępnych badań i ocena stanu zdrowia pacjenta, aby dostosować plan detoksykacji.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Monitorowanie medyczne:</strong> Stałe monitorowanie stanu zdrowia pacjenta przez zespół medyczny, w tym kontrola ciśnienia krwi, tętna i poziomu nawodnienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Łagodzenie objawów odstawienia:</strong> Podawanie odpowiednich leków i płynów w celu złagodzenia objawów odstawienia, takich jak drżenie, nudności, pocenie się, lęki i depresja.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie psychologiczne:</strong> Zapewnienie wsparcia psychologicznego przez terapeutów i specjalistów ds. uzależnień.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Dlaczego detoks jest konieczny?</h1>
        <p class="font-light text-gray-500 sm:text-lg">Detoksykacja narkotykowa jest konieczna z kilku kluczowych powodów:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Bezpieczeństwo:</strong> Nagłe odstawienie narkotyków może być niebezpieczne i prowadzić do poważnych komplikacji zdrowotnych, takich jak napady drgawkowe, halucynacje czy skrajne stany lękowe. Detoks pod nadzorem medycznym zapewnia bezpieczeństwo pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Łagodzenie objawów odstawienia:</strong> Profesjonalna detoksykacja pomaga złagodzić objawy odstawienia, co zmniejsza dyskomfort i ryzyko powikłań.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Przygotowanie do dalszej terapii:</strong> Detoksykacja jest pierwszym krokiem w leczeniu uzależnienia od narkotyków, przygotowując organizm pacjenta do dalszych etapów terapii, takich jak terapia indywidualna, grupowa i resocjalizacja.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Poprawa zdrowia fizycznego i psychicznego:</strong> Usunięcie narkotyków z organizmu poprawia ogólny stan zdrowia pacjenta, zarówno fizyczny, jak i psychiczny, co jest kluczowe dla sukcesu dalszego leczenia.</li>
        </ul>

        <p class="font-light text-gray-500 sm:text-lg">Detoksykacja narkotykowa to kluczowy krok w procesie zdrowienia z uzależnienia. Dzięki naszemu ośrodkowi, pacjenci mogą liczyć na bezpieczne i skuteczne odtrucie pod opieką doświadczonych specjalistów, co stanowi solidną podstawę do dalszej terapii i powrotu do zdrowego, wolnego od uzależnień życia.</p>
        `
    },
    "odtrucie_lekowe": {
        "title": "Odtrucie lekowe",
        "subTitle": "Odtrucie lekowe to proces niezbędny do odzyskania równowagi zdrowotnej. Nasz ośrodek oferuje specjalistyczną opiekę i wsparcie, aby bezpiecznie przejść przez ten trudny etap.",
        "contentImage": "https://images.unsplash.com/photo-1583604310111-9cd137d6ffe5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Odtrucie lekowe</h1>
        <p class="font-light text-gray-500 sm:text-lg">Odtrucie lekowe, znane również jako detoksykacja lekowa, to proces medyczny mający na celu usunięcie leków z organizmu pacjenta oraz złagodzenie objawów odstawienia. Jest to kluczowy pierwszy krok w leczeniu uzależnienia od leków, który przygotowuje pacjenta do dalszej terapii. Nasz ośrodek oferuje profesjonalne i bezpieczne odtrucie lekowe pod opieką doświadczonego zespołu medycznego.</p>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Na czym polega detoks</h1>
        <p class="font-light text-gray-500 sm:text-lg">Detoksykacja lekowa polega na monitorowanym procesie eliminacji leków z organizmu, który odbywa się pod nadzorem specjalistów. Proces ten obejmuje:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Ocena stanu zdrowia:</strong> Przeprowadzenie wstępnych badań i ocena stanu zdrowia pacjenta, aby dostosować plan detoksykacji.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Monitorowanie medyczne:</strong> Stałe monitorowanie stanu zdrowia pacjenta przez zespół medyczny, w tym kontrola ciśnienia krwi, tętna i poziomu nawodnienia.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Łagodzenie objawów odstawienia:</strong> Podawanie odpowiednich leków i płynów w celu złagodzenia objawów odstawienia, takich jak drżenie, nudności, pocenie się, lęki i depresja.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Wsparcie psychologiczne:</strong> Zapewnienie wsparcia psychologicznego przez terapeutów i specjalistów ds. uzależnień.</li>
        </ul>

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">Dlaczego detoks jest konieczny?</h1>
        <p class="font-light text-gray-500 sm:text-lg">Detoksykacja lekowa jest konieczna z kilku kluczowych powodów:</p>

        <ul class="list-disc my-4 mx-4">
            <li class="font-light text-gray-500 sm:text-lg"><strong>Bezpieczeństwo:</strong> Nagłe odstawienie leków może być niebezpieczne i prowadzić do poważnych komplikacji zdrowotnych, takich jak napady drgawkowe, halucynacje czy skrajne stany lękowe. Detoks pod nadzorem medycznym zapewnia bezpieczeństwo pacjenta.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Łagodzenie objawów odstawienia:</strong> Profesjonalna detoksykacja pomaga złagodzić objawy odstawienia, co zmniejsza dyskomfort i ryzyko powikłań.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Przygotowanie do dalszej terapii:</strong> Detoksykacja jest pierwszym krokiem w leczeniu uzależnienia od leków, przygotowując organizm pacjenta do dalszych etapów terapii, takich jak terapia indywidualna, grupowa i resocjalizacja.</li>
            <li class="font-light text-gray-500 sm:text-lg"><strong>Poprawa zdrowia fizycznego i psychicznego:</strong> Usunięcie leków z organizmu poprawia ogólny stan zdrowia pacjenta, zarówno fizyczny, jak i psychiczny, co jest kluczowe dla sukcesu dalszego leczenia.</li>
        </ul>

        <p class="font-light text-gray-500 sm:text-lg">Detoksykacja lekowa to kluczowy krok w procesie zdrowienia z uzależnienia. Dzięki naszemu ośrodkowi, pacjenci mogą liczyć na bezpieczne i skuteczne odtrucie pod opieką doświadczonych specjalistów, co stanowi solidną podstawę do dalszej terapii i powrotu do zdrowego, wolnego od uzależnień życia.</p>
        `
    },
    "blank": {
        "title": "",
        "subTitle": "",
        "contentImage": "",
        'content': ``
    },
}

export default articles