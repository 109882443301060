import React from 'react'
import { Link } from 'react-router-dom';

const PricingSimple = ({ props }) => {


    if (!props) return;

    const { title, span, price, table } = props;

    return (
        <div className="bg-white py-2 sm:py-2">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                {/* <div className="mx-auto max-w-2xl sm:text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.</p>
                </div> */}
                <div className="mx-auto mt-16 max-w-2xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                    <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">{title}</h3>
                        <p className="mt-4 leading-relaxed text-gray-500 font-light xl:text-lg">{span}</p>
                        <div className="mt-10 flex items-center gap-x-4 mb-4">
                            <h4 className="flex-none text-sm font-semibold leading-6 text-lime-700">Co zawiera</h4>
                            <div className="h-px flex-auto bg-gray-100"></div>
                        </div>
                        {/* <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                            {offertItems.map((element) => {
                                return (
                                    <li className="flex gap-x-3">
                                        <svg className="h-6 w-5 flex-none text-lime-700" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                        {element}
                                    </li>
                                )
                            })}
                        </ul> */}
                        <div className='bg-slate-50 border p-1 pb-0'>
                            {
                                table.map((element, key) => {
                                    console.log('element', element)
                                    const sizeOfRow = element.length;
                                    console.log('sizeOfRow', sizeOfRow)
                                    if (key === 0) {
                                        return (<div key={key} className={`grid grid-cols-3 gap-1`}>
                                            {
                                                element.map((el, key) => {
                                                    return (<div key={key} className='border bg-lime-600 text-white font-bold p-2 mb-1 leading-relaxed text-gray-500 xl:text-md'>{el}</div>)
                                                })
                                            }
                                        </div>)
                                    } else {
                                        return (<div key={key} className={`grid grid-cols-${sizeOfRow} gap-1`}>
                                            {
                                                element.map((el, key) => {
                                                    return (<div key={key} className='border bg-white p-2 mb-1 leading-relaxed text-gray-500 font-light xl:text-md'>{el}</div>)
                                                })
                                            }
                                        </div>)
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div className="h-full bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                            <div className="mx-auto w-full px-8">
                                {/* <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p> */}
                                {/* <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                    <span className="text-5xl font-bold tracking-tight text-gray-900">{price}</span>
                                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">zł</span>
                                </p> */}
                                {/* <input placeholder="Imię" type="text" className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-lime-200 transition duration-200 focus:border-lime-400 focus:outline-none focus:ring" id="firstName" name="firstName" value=""></input>
                                <input placeholder="E-mail" type="text" className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-lime-200 transition duration-200 focus:border-lime-400 focus:outline-none focus:ring" id="firstName" name="firstName" value=""></input> */}
                                <div className="max-w-sm mx-auto">
                                    <select id="countries" className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-lime-200 transition duration-200 focus:border-lime-400 focus:outline-none focus:ring text-black">
                                        <option selected>-- Wybierz --</option>
                                        {table.map((element,key)=>{
                                            
                                            if(key > 0){
                                                return(<option value={
                                                    {
                                                        regularPrice: element[1],
                                                        salePrice: element[2],
                                                    }
                                                }>{element[0]}</option>)
                                            }
                                        })}
                                        
                                    </select>
                                </div>
                                <a href="#" className="relative inline-flex h-12 w-full items-center justify-center bg-lime-600 px-6 font-medium tracking-wide text-white shadow-md ring-lime-200 transition duration-200 hover:bg-lime-700 focus:outline-none focus:ring">Wybieram</a>
                                <p className="my-6 text-xs leading-5 text-gray-600">Lub</p>
                                <Link to="#" className="mb-2 relative inline-flex h-12 w-full items-center justify-center bg-lime-600 px-6 font-medium tracking-wide text-white shadow-md ring-lime-200 transition duration-200 hover:bg-lime-700 focus:outline-none focus:ring">Skontaktuj się z nami</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PricingSimple