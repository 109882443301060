import React from 'react'
import { Link } from 'react-router-dom';

const Gallery = ({ props }) => {

    if (!props) return;

    const { images, title, subTitle } = props;

    return (
        <div className="bg-white py-6 sm:py-8 lg:py-12">
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">

                <div className="mb-10 md:mb-16">
                    <h2 className="mb-4 text-2xl font-extrabold text-gray-800 md:mb-6 lg:text-4xl text-center">{title}</h2>

                    <p className="mx-auto max-w-screen-md text-justify md:text-center font-light text-gray-500 sm:text-lg">{subTitle}</p>
                </div>

                <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">

                    {images.map((element) => {
                        return (<Link key={element.id} to="#" className="group relative flex h-48 items-end justify-end overflow-hidden  bg-gray-100 shadow-lg md:h-96">
                            <img alt={element.span} src={element.src} loading="lazy" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                            <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>

                            {/* <span className="relative mr-3 mb-3 inline-block border border-gray-500 px-2 py-1 text-xs text-gray-200 backdrop-blur md:px-3 md:text-sm">{element.span}</span> */}
                        </Link>)
                    })}

                </div>
            </div>
        </div>
    )
}

export default Gallery