const data = {
    navigationLinks: [
        {
            "id": 1,
            "href": "/",
            "label": "Start"
        },
        {
            "id": 2,
            "href": "/onas",
            "label": "O nas",
            "menuTree": [
                {
                    "id": 1,
                    "href": "/onas_more",
                    "label": "Kim jesteśmy"
                },
                {
                    "id": 2,
                    "href": "/onas_more",
                    "label": "Ośrodek"
                },
            ]
        },
        {
            "id": 3,
            "href": "/detoks",
            "label": "Detoks"
        },
        {
            "id": 4,
            "href": "/terapia",
            "label": "Terapia"
        },
        {
            "id": 5,
            "href": "/wsparcie",
            "label": "Wsparcie"
        },
        {
            "id": 6,
            "href": "/cennik",
            "label": "Cennik"
        },
        {
            "id": 7,
            "href": "/kontakt",
            "label": "Kontakt"
        }
    ]

}

export default data;