import React, { useEffect, useState } from 'react'
import { Spinner, Alert, Button } from '../../components'

const Appointment = () => {

    const [userData, setUserData] = useState({
        name: "",
        email: "",
        phone: "",
        date: {
            from: "",
            to: ""
        }
    })

    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState({
        isVisible: false,
        type: "",
        title: "",
        message: ""
    })
    
    const sendData = () => {

        const { name, email, date, phone } = userData;

        if (!name || !email || !date.from || !date.to) {
            setAlert({
                isVisible: true,
                type: 'error',
                title: 'Błąd',
                message: "Wszystkie pola są wymagane."
            });
            return;
        }

        if (date.from > date.to) {
            setAlert({
                isVisible: true,
                type: 'error',
                title: 'Błąd',
                message: "Data 'od' nie może być większa od daty 'do'."
            });
            return;
        }

        setAlert({
            isVisible: false,
            type: '',
            title: '',
            message: ""
        });

        setIsLoading(true);

        setTimeout(() => {
            setUserData({
                name: "",
                email: "",
                phone: "",
                date: {
                    from: "",
                    to: ""
                }
            });
            setIsLoading(false);
            setAlert({
                isVisible: true,
                type: 'success',
                title: 'Dziękujemy',
                message: "Nasi specjaliści wkrótce się odezwą."
            });
        }, 1000);


    }

    return (
        <>
            <div className="w-full max-w-xl xl:w-5/12 xl:px-8">
                <div className="overflow-hidden bg-white p-7 shadow-2xl sm:p-10">
                    <h3 className="mb-4 text-xl font-bold text-lime-600 sm:mb-6 sm:text-center sm:text-2xl">Umów bezpłatną konsultację</h3>
                    <Alert type={alert.type} message={alert.message} title={alert.title} visible={alert.isVisible} />
                    <div>
                        <div className="mb-1 sm:mb-2">
                            <label htmlFor="firstName" className="mb-1 inline-block font-medium text-lime-600">Imię</label>
                            <input
                                onChange={(e) => {
                                    setUserData(prevState => ({
                                        ...prevState,
                                        name: e.target.value
                                    }));
                                }}
                                value={userData.name}
                                placeholder="Imię" required="" type="text" className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-lime-200 transition duration-200 focus:border-lime-400 focus:outline-none focus:ring" id="firstName" name="firstName" />
                        </div>
                        <div className="mb-1 sm:mb-2">
                            <label htmlFor="email" className="mb-1 inline-block font-medium text-lime-600">E-mail</label>
                            <input
                                onChange={(e) => {
                                    setUserData(prevState => ({
                                        ...prevState,
                                        email: e.target.value
                                    }));
                                }}
                                value={userData.email}
                                placeholder="E-mail" required="" type="email" className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-lime-200 transition duration-200 focus:border-lime-400 focus:outline-none focus:ring" id="email" name="email" />
                        </div>
                        <div className="mb-1 sm:mb-2">
                            <label htmlFor="email" className="mb-1 inline-block font-medium text-lime-600">Telefon</label>
                            <input
                                onChange={(e) => {
                                    setUserData(prevState => ({
                                        ...prevState,
                                        phone: e.target.value
                                    }));
                                }}
                                value={userData.phone}
                                placeholder="(opcjonalnie)" required="" type="email" className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-lime-200 transition duration-200 focus:border-lime-400 focus:outline-none focus:ring" id="email" name="email" />
                        </div>
                        <div className="mb-1 sm:mb-2">
                            <div className='flex gap-5 flex-col md:flex-row'>
                                <div className='flex flex-col w-full'>
                                    <label htmlFor="fromDate" className="mb-1 inline-block font-medium text-lime-600">Data</label>
                                    <input
                                        onChange={(e) => {
                                            setUserData(prevState => ({
                                                ...prevState,
                                                date: {
                                                    ...prevState.date,
                                                    from: e.target.value
                                                }
                                            }));
                                        }}
                                        value={userData.date.from}
                                        placeholder="Od" required="" type="date" className="md:mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-lime-200 transition duration-200 focus:border-lime-400 focus:outline-none focus:ring" id="fromDate" name="fromDate" />
                                </div>

                                <div className='flex flex-col w-full'>
                                    <label htmlFor="toDate" className="mb-1 inline-block font-medium text-lime-600">Godzina</label>
                                    <input
                                        onChange={(e) => {
                                            setUserData(prevState => ({
                                                ...prevState,
                                                date: {
                                                    ...prevState.date,
                                                    to: e.target.value
                                                }
                                            }));
                                        }}
                                        value={userData.date.to}
                                        placeholder="Do" required="" type="time" className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-lime-200 transition duration-200 focus:border-lime-400 focus:outline-none focus:ring" id="toDate" name="toDate" />
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 mb-2 sm:mb-4">
                            <Button onClick={sendData} label={"Zapisz się"} isLoading={isLoading}/>
                        </div>
                        <a href="" className='hover:underline'>
                            <p className="text-xs text-gray-600 sm:text-sm">Przetwarzanie danych osobowych</p>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Appointment
