import React from 'react'
import { Footer, NavBar, SimpleHero } from '../../components/components'
import data from '../../data/navigation.tsx'

const Contact = () => {
    return (
        <>
            <NavBar current="/kontakt" props={data} />
            <SimpleHero props={
                {
                    title: "Kontakt",
                    span: "Potrzebujesz więcej informacji? Skontaktuj się z nami, aby umówić się na konsultację, zadać pytania lub dowiedzieć się więcej o naszych usługach. Jesteśmy tutaj, aby pomóc.",
                    image: "https://images.unsplash.com/photo-1543269865-cbf427effbad?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
            } />
            <Footer />
        </>
    )
}

export default Contact