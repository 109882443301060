import React from 'react'

const ArticleContent = ({ props }) => {
    return (
        <div className='mx-auto max-w-screen-lg px-4 py-24 md:px-8'>
            <div dangerouslySetInnerHTML={{ __html: props }} />
        </div>
    )
}

export default ArticleContent