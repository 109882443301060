import React from 'react'
import { Footer, NavBar, SimpleHero, PricingSimple, CheckoutPayment } from '../../components/components'
import data from '../../data/navigation.tsx'

const Pricing = () => {
    return (
        <>
            <NavBar current={"/cennik"} props={data} />
            <SimpleHero props={
                {
                    title: "Cennik",
                    span: "Sprawdź nasz przejrzysty i konkurencyjny cennik. Dowiedz się więcej o kosztach i pakietach, które sprawiają, że nasze usługi terapeutyczne i detoksykacyjne są dostępne dla każdego.",
                    image: "https://images.unsplash.com/photo-1579208575657-c595a05383b7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
            } />
            <PricingSimple props={
                {
                    title: "SZYBKI DETOKS",
                    span: "Nasza cennik Szybki Detoks to intensywny program detoksykacji zaprojektowany dla osób potrzebujących szybkiego i bezpiecznego oczyszczenia organizmu. Oferujemy profesjonalną opiekę i wsparcie, aby pomóc Ci szybko wrócić do zdrowia..",
                    price: 1000,
                    table: [
                        ["CZAS TRWANIA", "CENA", "PROMOCJA*"],
                        ["POBYT BEZ NOCLEGU	", "1 000zł", "800zł"],
                    ]
                }
            } />
            <PricingSimple props={
                {
                    title: "DETOKSYKACJA ALKOHOLOWA, NARKOTYKOWA LUB LEKOWA",
                    span: "Oferujemy specjalistyczne programy detoksykacji alkoholowej, narkotykowej oraz lekowej. Nasze podejście zapewnia bezpieczne i skuteczne oczyszczenie organizmu pod opieką doświadczonego zespołu, pomagając Ci rozpocząć zdrowe życie.",
                    price: 1000,
                    table: [
                        ["CZAS TRWANIA", "CENA", "PROMOCJA*"],
                        ["1 doba", "1 200zł", "1 000zł"],
                        ["2 doby", "2 400zł", "2 000zł"],
                    ]
                }
            } />
            <CheckoutPayment />
            <Footer />
        </>
    )
}

export default Pricing