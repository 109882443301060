import React from 'react'
import { NavBar, PageNotFound } from '../../components/components'

const NoPage = () => {
  return (
    <>
      <PageNotFound />
    </>
  )
}

export default NoPage