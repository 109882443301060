import React, { useEffect } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import { AboutUs, Detoks, Home, NoPage, Therapy, Support, Pricing, Contact, Article, UI, Offert } from './routing/routing';

const App = () => {

  const {pathname} = useLocation();


  useEffect(()=>{
    window.scrollTo(0, 0);
  },[pathname])

  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/onas', element: <AboutUs /> },
    { path: '/detoks', element: <Detoks /> },
    { path: '/detoks/:articleId', element: <Article current={'/detoks'}/> },
    { path: '/terapia', element: <Therapy /> },
    { path: '/terapia/:articleId', element: <Article current={'/terapia'}/> },
    { path: '/wsparcie', element: <Support /> },
    { path: '/wsparcie/:articleId', element: <Article current={'/wsparcie'}/> },
    { path: '/cennik', element: <Pricing /> },
    { path: '/kontakt', element: <Contact /> },
    { path: '/oferta', element: <Offert /> },
    { path: '/UI', element: <UI /> },
    { path: '*', element: <NoPage /> },
  ]);

  return routes;
}

export default App