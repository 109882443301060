import React from 'react'

const UI = () => {
    return (
        <>
            <div class="container mx-auto px-4">
                <div className="section border-b px-4 py-8">
                    <span className='block text-gray-500 pb-4'>Headings</span>
                    <h1 className='text-5xl font-extrabold'>h1 Lorem ipsum</h1>
                    <h2 className='text-4xl font-extrabold'>h2 Lorem ipsum</h2>
                    <h3 className='text-3xl font-bold'>h3 Lorem ipsum</h3>
                    <h4 className='text-2xl font-bold'>h4 Lorem ipsum</h4>
                    <h5 className='text-xl font-bold'>h5 Lorem ipsum</h5>
                    <h6 className='text-lg font-bold'>h6 Lorem ipsum</h6>
                </div>
                <div className="section border-b px-4 py-8">
                    <span className='block text-gray-500 pb-4'>Paragraph</span>
                    <p className='font-light text-gray-500 sm:text-lg'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <div className="section border-b px-4 py-8">
                    <span className='block text-gray-500 pb-4'>Colors</span>
                    <div className="flex items-center mb-2">
                        <div className="picker w-6 h-6 bg-lime-600 mr-1 rounded-md"></div>
                        bg-lime-600
                    </div>
                    <div className="flex items-center mb-2">
                        <div className="picker w-6 h-6 bg-lime-700 mr-1 rounded-md"></div>
                        hover:bg-lime-700
                    </div>
                </div>
                <div className="section border-b px-4 py-8">
                    <span className='block text-gray-500 pb-4'>Buttons</span>
                    <div className="max-w-sm mb-4">
                        <button className='relative inline-flex h-12 w-full items-center justify-center bg-lime-600 px-6 font-medium tracking-wide text-white shadow-md ring-lime-200 transition duration-200 hover:bg-lime-700 focus:outline-none focus:ring cursor-pointer'>Primary button</button>
                    </div>
                    <div className="max-w-sm mb-4">
                        <button className='relative inline-flex h-12 w-full items-center justify-center bg-white hover:bg-gray-50 px-6 font-medium tracking-wide text-lime-700 shadow-md ring-lime-200 transition duration-200 focus:outline-none focus:ring'>Secondary button</button>
                    </div>
                    <div className="max-w-sm mb-4">
                        <button className='relative inline-flex h-12 w-full items-center justify-center bg-lime-600 hover:bg-lime-700 px-6 font-medium tracking-wide text-white shadow-md ring-lime-200 transition duration-200 focus:outline-none focus:ring'>Success button</button>
                    </div>
                    <div className="max-w-sm mb-4">
                        <button className='relative inline-flex h-12 w-full items-center justify-center bg-lime-600 hover:bg-lime-700 px-6 font-medium tracking-wide text-white shadow-md ring-lime-200 transition duration-200 focus:outline-none focus:ring'>Danger button</button>
                    </div>
                    <div className="max-w-sm mb-4">
                        <button className='inline-flex items-center font-semibold tracking-wider text-lime-600 transition-colors duration-200 hover:text-lime-700'>Link button</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UI