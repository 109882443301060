import React from 'react'
import { Footer, NavBar, SimpleHero, Features, SimpleCta } from '../../components/components'
import data from '../../data/navigation.tsx'

const Therapy = () => {
    return (
        <>
            <NavBar current={"/terapia"} props={data} />
            <SimpleHero props={
                {
                    title: "Terapia",
                    span: "Poznaj szeroką gamę terapii, które oferujemy, w tym terapię indywidualną, grupową, rodzinną i terapię nawrotów. Nasze indywidualne podejście pomoże Ci odzyskać kontrolę nad swoim życiem.",
                    image: "https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
            } />
            <Features props={
                {
                    title: "RODZAJE UZALEŻNIEŃ KTÓRE LECZYMY",
                    subTitle: "This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text but is random or otherwise generated.",
                    content: [
                        {
                            "id": "1",
                            "title": "Alkoholizm",
                            "content": "Alkoholizm to nie tylko problem z piciem, to choroba, która dotyka całe życie. Razem możemy z nią walczyć i przywrócić równowagę oraz zdrowie.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "alkoholizm",
                            "image": "https://images.unsplash.com/photo-1676629872240-8a123a83f0a7?q=80&w=2065&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "2",
                            "title": "Narkomania",
                            "content": "Narkomania to niebezpieczna pułapka, która niszczy życie. Nasza misja to wspólna walka i pomoc w odzyskaniu kontroli oraz zdrowia.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "narkomania",
                            "image": "https://images.unsplash.com/photo-1529059356407-0114ed24b913?q=80&w=2111&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        },
                        {
                            "id": "3",
                            "title": "Lekomania",
                            "content": "Lekomania to uzależnienie, które może zniszczyć zdrowie i życie. Wspólnie możemy przezwyciężyć ten problem i wrócić do pełni sił.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "lekomania",
                            "image": "https://images.unsplash.com/photo-1675524375058-0b0f72f5d3b8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "4",
                            "title": "Hazard",
                            "content": "Hazard to uzależnienie, które może prowadzić do poważnych problemów finansowych i osobistych. Razem możemy odzyskać kontrolę nad życiem.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "hazard",
                            "image": "https://images.unsplash.com/photo-1608231883522-2efb1897a608?q=80&w=1980&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "5",
                            "title": "Seksoholizm",
                            "content": "Seksoholizm to uzależnienie, które może zrujnować relacje i życie osobiste. Wspólnie możemy znaleźć drogę do zdrowia i harmonii.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "seksoholizm",
                            "image": "https://images.unsplash.com/photo-1606103836293-0a063ee20566?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "6",
                            "title": "Mieszane",
                            "content": "Uzależnienia mieszane to złożony problem, który wymaga wszechstronnego podejścia. Razem znajdziemy drogę do zdrowia i równowagi.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "mieszane",
                            "image": "https://images.unsplash.com/photo-1472566316349-bce77aa2a778?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                    ]
                }
            } />
            <SimpleCta props={
                {
                    title: "Dołącz do nas i zacznij odzyskiwać kontrolę nad swoim życiem",
                    subTitle: "Zachęcamy Cię do dołączenia do naszej społeczności, gdzie razem możemy rozpocząć Twoją drogę do zdrowia i wolności od uzależnień. Daj sobie szansę na profesjonalną pomoc i wsparcie dostosowane specjalnie do Twoich potrzeb.",
                }
            } />
            <Footer />
        </>
    )
}

export default Therapy