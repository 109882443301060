import React from 'react';

const alertStyles = {
    error: {
        container: "mb-4 bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md",
        icon: "text-red-500",
    },
    success: {
        container: "mb-4 bg-lime-100 border-t-4 border-lime-500 rounded-b text-lime-900 px-4 py-3 shadow-md",
        icon: "text-lime-500",
    },
};

const Alert = ({ title = "", message = "", type = "success", visible = false }) => {
    if (!visible) return null;

    const { container, icon } = alertStyles[type] || alertStyles.success;

    return (
        <div className={container} role="alert">
            <div className="flex">
                <div className="py-1">
                    <svg className={`fill-current h-6 w-6 ${icon} mr-4`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                </div>
                <div>
                    <p className="font-bold">{title}</p>
                    <p className="text-sm">{message}</p>
                </div>
            </div>
        </div>
    );
};

export default Alert;
