import React from 'react'
import { Alert } from '../../components';

const VideoPlayer = ({ source }) => {

    if (source === undefined || source.length === 0) {
        return (<div className='px-24 mx-auto'>
            <Alert title='Error' message='Missing source in VideoPlayer component.' type='error' visible={true} />
        </div>);
    }

    return (
        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
            <video src={source} controls />
        </div>
    )
}

export default VideoPlayer