import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArticleContent, Footer, NavBar, SimpleCta, SimpleHero } from '../../components/components';
import data from '../../data/navigation.tsx';
import articles from '../../data/article/article.jsx';

const Article = ({current}) => {
    const { articleId } = useParams();
    const [article, setArticle] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getArticle = (articleId) => {
            return articles[articleId];
        };

        const fetchedArticle = getArticle(articleId);

        if (!fetchedArticle) {
            navigate('/404');
        } else {
            setArticle(fetchedArticle);
        }
    }, [articleId, navigate]);

    if (!article) {
        return <div></div>;
    }

    return (
        <>
            <NavBar props={data} current={current}/>
            <SimpleHero props={{
                title: article.title,
                span: article.subTitle,
                image: article.contentImage
            }} />
            <ArticleContent props={article.content} />
            <SimpleCta props={
                {
                    title: "Dołącz do nas i zacznij odzyskiwać kontrolę nad swoim życiem",
                    subTitle: "Zachęcamy Cię do dołączenia do naszej społeczności, gdzie razem możemy rozpocząć Twoją drogę do zdrowia i wolności od uzależnień. Daj sobie szansę na profesjonalną pomoc i wsparcie dostosowane specjalnie do Twoich potrzeb.",
                }
            } />
            <Footer />
        </>
    );
};

export default Article;